import { DATE_FORMAT } from '@/utils/Format'
import { isInThePast } from '@/utils/helper'
import moment from 'moment'

export default class Domain {
  constructor(item = {}) {
    return {
      id: item.id,
      name: item.name,
      target_url: item.target_url || '',
      domain_expire_date: item.domain_expire_date ? moment(item.domain_expire_date).format(DATE_FORMAT) : '',
      did_phone: item.did_phone ? JSON.parse(item.did_phone) : [],
      status: item.status,
      actual_campaign_end_date: item.actual_campaign_end_date ? moment(item.actual_campaign_end_date).format(DATE_FORMAT) : '',
      isDomainExpired: item.domain_expire_date ? isInThePast(item.domain_expire_date) : false,
    }
  }
}
