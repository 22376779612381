import Domain from '@/models/Domain'

export default class DomainFactory {
  static createFromJson(json) {
    return new Domain(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(DomainFactory.createFromJson(item))
    })

    return jsonData
  }
}
